.activo {
  border: 2px solid #20bf6b !important;
}
.btnFeatures {
  outline: 0 !important;
  cursor: initial !important;
}
.btnFeatures:not(.activo) {
  border: 2px solid red !important;
}
.btnFeatures:focus,
.btnFeatures:hover,
.btnFeatures:hover .tooltiptext {
  background-color: #fff !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  visibility: visible;
}
/* Tooltip text */
.tooltiptext {
  margin-top: 15px;
  visibility: hidden;
  background-color: black;
  color: black;
  text-align: center;
  padding: 5px 5px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  border: 2px solid black;
}
