.MfMbj1IVkeKJ67QO4OWwn .fa-check-circle-o {
  color: #155724;
}
.MfMbj1IVkeKJ67QO4OWwn .fa-exclamation-circle {
  color: #856404;
}
._3akOaUViObBCioDuwHK6uc #root__title {
  font-size: 1.8rem;
}
._3akOaUViObBCioDuwHK6uc .form-group {
  margin-top: 5px;
  margin-bottom: 10px;
}
._3akOaUViObBCioDuwHK6uc .btn-add,
._3akOaUViObBCioDuwHK6uc .array-item-move-up,
._3akOaUViObBCioDuwHK6uc .array-item-move-down,
._3akOaUViObBCioDuwHK6uc .array-item-remove {
  font: normal normal normal 14px/1 FontAwesome;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
._3akOaUViObBCioDuwHK6uc .array-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0px;
  border-top: 1px solid #ddd;
}
._3akOaUViObBCioDuwHK6uc .array-item:first-child {
  border-top: none;
}
._3akOaUViObBCioDuwHK6uc .form-control {
  height: 30px;
  font-family: sans-serif;
  font-size: 1.22rem;
}
._3akOaUViObBCioDuwHK6uc i.glyphicon {
  display: none;
}
._3akOaUViObBCioDuwHK6uc .btn-add::after {
  content: "\F067";
}
._3akOaUViObBCioDuwHK6uc .array-item-move-up::after {
  content: "\F062";
}
._3akOaUViObBCioDuwHK6uc .array-item-move-down::after {
  content: "\F063";
}
._3akOaUViObBCioDuwHK6uc .array-item-remove::after {
  content: "\F068";
}
