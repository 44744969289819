.platform-list-instagram {
  background-color: #833ab4;
}
.platform-list-instagram:nth-of-type(odd) {
  background-color: #833ab4 !important;
}
.platform-list-instagram:hover {
  color: #FFFFFF;
  background-color: #833ab4 !important;
}
.platform-list-instagram:hover a {
  color: #FFFFFF;
}
.platform-list-instagram .label-accounts {
  font-size: 20px;
}
.platform-list-instagram td:first-child,
.platform-list-instagram:nth-of-type(odd) td:first-child {
  font-size: 16px;
  background-color: #833ab4;
  color: #FFFFFF;
}
.platform-list-instagram td:first-child a,
.platform-list-instagram:nth-of-type(odd) td:first-child a,
.platform-list-instagram td:first-child pre,
.platform-list-instagram:nth-of-type(odd) td:first-child pre {
  color: #FFFFFF;
}
.platform-list-instagram td:first-child a:hover,
.platform-list-instagram:nth-of-type(odd) td:first-child a:hover,
.platform-list-instagram td:first-child a:active,
.platform-list-instagram:nth-of-type(odd) td:first-child a:active,
.platform-list-instagram td:first-child a:focus,
.platform-list-instagram:nth-of-type(odd) td:first-child a:focus {
  text-decoration: none;
}
.platform-list-instagram td:first-child .platform,
.platform-list-instagram:nth-of-type(odd) td:first-child .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-instagram td:first-child .platform i,
.platform-list-instagram:nth-of-type(odd) td:first-child .platform i {
  font-size: 24px;
}
.platform-list-instagram td:first-child .label,
.platform-list-instagram:nth-of-type(odd) td:first-child .label {
  margin-left: 10px;
}
.platform-list-messenger {
  background-color: #1787fb;
}
.platform-list-messenger:nth-of-type(odd) {
  background-color: #1787fb !important;
}
.platform-list-messenger:hover {
  color: #FFFFFF;
  background-color: #1787fb !important;
}
.platform-list-messenger:hover a {
  color: #FFFFFF;
}
.platform-list-messenger .label-accounts {
  font-size: 20px;
}
.platform-list-messenger td:first-child,
.platform-list-messenger:nth-of-type(odd) td:first-child {
  font-size: 16px;
  background-color: #1787fb;
  color: #FFFFFF;
}
.platform-list-messenger td:first-child a,
.platform-list-messenger:nth-of-type(odd) td:first-child a,
.platform-list-messenger td:first-child pre,
.platform-list-messenger:nth-of-type(odd) td:first-child pre {
  color: #FFFFFF;
}
.platform-list-messenger td:first-child a:hover,
.platform-list-messenger:nth-of-type(odd) td:first-child a:hover,
.platform-list-messenger td:first-child a:active,
.platform-list-messenger:nth-of-type(odd) td:first-child a:active,
.platform-list-messenger td:first-child a:focus,
.platform-list-messenger:nth-of-type(odd) td:first-child a:focus {
  text-decoration: none;
}
.platform-list-messenger td:first-child .platform,
.platform-list-messenger:nth-of-type(odd) td:first-child .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-messenger td:first-child .platform i,
.platform-list-messenger:nth-of-type(odd) td:first-child .platform i {
  font-size: 24px;
}
.platform-list-messenger td:first-child .label,
.platform-list-messenger:nth-of-type(odd) td:first-child .label {
  margin-left: 10px;
}
.platform-list-telegram {
  background-color: #249ddb;
}
.platform-list-telegram:nth-of-type(odd) {
  background-color: #249ddb !important;
}
.platform-list-telegram:hover {
  color: #FFFFFF;
  background-color: #249ddb !important;
}
.platform-list-telegram:hover a {
  color: #FFFFFF;
}
.platform-list-telegram .label-accounts {
  font-size: 20px;
}
.platform-list-telegram td:first-child,
.platform-list-telegram:nth-of-type(odd) td:first-child {
  font-size: 16px;
  background-color: #249ddb;
  color: #FFFFFF;
}
.platform-list-telegram td:first-child a,
.platform-list-telegram:nth-of-type(odd) td:first-child a,
.platform-list-telegram td:first-child pre,
.platform-list-telegram:nth-of-type(odd) td:first-child pre {
  color: #FFFFFF;
}
.platform-list-telegram td:first-child a:hover,
.platform-list-telegram:nth-of-type(odd) td:first-child a:hover,
.platform-list-telegram td:first-child a:active,
.platform-list-telegram:nth-of-type(odd) td:first-child a:active,
.platform-list-telegram td:first-child a:focus,
.platform-list-telegram:nth-of-type(odd) td:first-child a:focus {
  text-decoration: none;
}
.platform-list-telegram td:first-child .platform,
.platform-list-telegram:nth-of-type(odd) td:first-child .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-telegram td:first-child .platform i,
.platform-list-telegram:nth-of-type(odd) td:first-child .platform i {
  font-size: 24px;
}
.platform-list-telegram td:first-child .label,
.platform-list-telegram:nth-of-type(odd) td:first-child .label {
  margin-left: 10px;
}
.platform-list-webchattonic {
  background-color: #ed8a9d;
}
.platform-list-webchattonic:nth-of-type(odd) {
  background-color: #ed8a9d !important;
}
.platform-list-webchattonic:hover {
  color: #FFFFFF;
  background-color: #ed8a9d !important;
}
.platform-list-webchattonic:hover a {
  color: #FFFFFF;
}
.platform-list-webchattonic .label-accounts {
  font-size: 20px;
}
.platform-list-webchattonic td:first-child,
.platform-list-webchattonic:nth-of-type(odd) td:first-child {
  font-size: 16px;
  background-color: #ed8a9d;
  color: #FFFFFF;
}
.platform-list-webchattonic td:first-child a,
.platform-list-webchattonic:nth-of-type(odd) td:first-child a,
.platform-list-webchattonic td:first-child pre,
.platform-list-webchattonic:nth-of-type(odd) td:first-child pre {
  color: #FFFFFF;
}
.platform-list-webchattonic td:first-child a:hover,
.platform-list-webchattonic:nth-of-type(odd) td:first-child a:hover,
.platform-list-webchattonic td:first-child a:active,
.platform-list-webchattonic:nth-of-type(odd) td:first-child a:active,
.platform-list-webchattonic td:first-child a:focus,
.platform-list-webchattonic:nth-of-type(odd) td:first-child a:focus {
  text-decoration: none;
}
.platform-list-webchattonic td:first-child .platform,
.platform-list-webchattonic:nth-of-type(odd) td:first-child .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-webchattonic td:first-child .platform i,
.platform-list-webchattonic:nth-of-type(odd) td:first-child .platform i {
  font-size: 24px;
}
.platform-list-webchattonic td:first-child .label,
.platform-list-webchattonic:nth-of-type(odd) td:first-child .label {
  margin-left: 10px;
}
.platform-list-whatsapp {
  background-color: #25e47b;
}
.platform-list-whatsapp:nth-of-type(odd) {
  background-color: #25e47b !important;
}
.platform-list-whatsapp:hover {
  color: #FFFFFF;
  background-color: #25e47b !important;
}
.platform-list-whatsapp:hover a {
  color: #FFFFFF;
}
.platform-list-whatsapp .label-accounts {
  font-size: 20px;
}
.platform-list-whatsapp td:first-child,
.platform-list-whatsapp:nth-of-type(odd) td:first-child {
  font-size: 16px;
  background-color: #25e47b;
  color: #FFFFFF;
}
.platform-list-whatsapp td:first-child a,
.platform-list-whatsapp:nth-of-type(odd) td:first-child a,
.platform-list-whatsapp td:first-child pre,
.platform-list-whatsapp:nth-of-type(odd) td:first-child pre {
  color: #FFFFFF;
}
.platform-list-whatsapp td:first-child a:hover,
.platform-list-whatsapp:nth-of-type(odd) td:first-child a:hover,
.platform-list-whatsapp td:first-child a:active,
.platform-list-whatsapp:nth-of-type(odd) td:first-child a:active,
.platform-list-whatsapp td:first-child a:focus,
.platform-list-whatsapp:nth-of-type(odd) td:first-child a:focus {
  text-decoration: none;
}
.platform-list-whatsapp td:first-child .platform,
.platform-list-whatsapp:nth-of-type(odd) td:first-child .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-whatsapp td:first-child .platform i,
.platform-list-whatsapp:nth-of-type(odd) td:first-child .platform i {
  font-size: 24px;
}
.platform-list-whatsapp td:first-child .label,
.platform-list-whatsapp:nth-of-type(odd) td:first-child .label {
  margin-left: 10px;
}
.platform-list-unknown {
  background-color: #ffffff;
}
.platform-list-unknown:nth-of-type(odd) {
  background-color: #ffffff !important;
}
.platform-list-unknown:hover {
  color: #000000;
  background-color: #ffffff !important;
}
.platform-list-unknown:hover a {
  color: #000000;
}
.platform-list-unknown .label-accounts {
  font-size: 20px;
}
.platform-list-unknown td:first-child,
.platform-list-unknown:nth-of-type(odd) td:first-child {
  font-size: 16px;
  background-color: #ffffff;
  color: #000000;
}
.platform-list-unknown td:first-child a,
.platform-list-unknown:nth-of-type(odd) td:first-child a,
.platform-list-unknown td:first-child pre,
.platform-list-unknown:nth-of-type(odd) td:first-child pre {
  color: #000000;
}
.platform-list-unknown td:first-child a:hover,
.platform-list-unknown:nth-of-type(odd) td:first-child a:hover,
.platform-list-unknown td:first-child a:active,
.platform-list-unknown:nth-of-type(odd) td:first-child a:active,
.platform-list-unknown td:first-child a:focus,
.platform-list-unknown:nth-of-type(odd) td:first-child a:focus {
  text-decoration: none;
}
.platform-list-unknown td:first-child .platform,
.platform-list-unknown:nth-of-type(odd) td:first-child .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-unknown td:first-child .platform i,
.platform-list-unknown:nth-of-type(odd) td:first-child .platform i {
  font-size: 24px;
}
.platform-list-unknown td:first-child .label,
.platform-list-unknown:nth-of-type(odd) td:first-child .label {
  margin-left: 10px;
}
.platform-list-instagram-complete {
  background-color: #833ab4;
}
.platform-list-instagram-complete:nth-of-type(odd) {
  background-color: #833ab4 !important;
}
.platform-list-instagram-complete:hover {
  color: #FFFFFF;
  background-color: #833ab4 !important;
}
.platform-list-instagram-complete:hover a {
  color: #FFFFFF;
}
.platform-list-instagram-complete .label-accounts {
  font-size: 20px;
}
.platform-list-instagram-complete td,
.platform-list-instagram-complete:nth-of-type(odd) td {
  font-size: 16px;
  background-color: #833ab4;
  color: #FFFFFF;
}
.platform-list-instagram-complete td a,
.platform-list-instagram-complete:nth-of-type(odd) td a,
.platform-list-instagram-complete td pre,
.platform-list-instagram-complete:nth-of-type(odd) td pre {
  color: #FFFFFF;
}
.platform-list-instagram-complete td a:hover,
.platform-list-instagram-complete:nth-of-type(odd) td a:hover,
.platform-list-instagram-complete td a:active,
.platform-list-instagram-complete:nth-of-type(odd) td a:active,
.platform-list-instagram-complete td a:focus,
.platform-list-instagram-complete:nth-of-type(odd) td a:focus {
  text-decoration: none;
}
.platform-list-instagram-complete td .platform,
.platform-list-instagram-complete:nth-of-type(odd) td .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-instagram-complete td .platform i,
.platform-list-instagram-complete:nth-of-type(odd) td .platform i {
  font-size: 24px;
}
.platform-list-instagram-complete td .label,
.platform-list-instagram-complete:nth-of-type(odd) td .label {
  margin-left: 10px;
}
.platform-list-messenger-complete {
  background-color: #1787fb;
}
.platform-list-messenger-complete:nth-of-type(odd) {
  background-color: #1787fb !important;
}
.platform-list-messenger-complete:hover {
  color: #FFFFFF;
  background-color: #1787fb !important;
}
.platform-list-messenger-complete:hover a {
  color: #FFFFFF;
}
.platform-list-messenger-complete .label-accounts {
  font-size: 20px;
}
.platform-list-messenger-complete td,
.platform-list-messenger-complete:nth-of-type(odd) td {
  font-size: 16px;
  background-color: #1787fb;
  color: #FFFFFF;
}
.platform-list-messenger-complete td a,
.platform-list-messenger-complete:nth-of-type(odd) td a,
.platform-list-messenger-complete td pre,
.platform-list-messenger-complete:nth-of-type(odd) td pre {
  color: #FFFFFF;
}
.platform-list-messenger-complete td a:hover,
.platform-list-messenger-complete:nth-of-type(odd) td a:hover,
.platform-list-messenger-complete td a:active,
.platform-list-messenger-complete:nth-of-type(odd) td a:active,
.platform-list-messenger-complete td a:focus,
.platform-list-messenger-complete:nth-of-type(odd) td a:focus {
  text-decoration: none;
}
.platform-list-messenger-complete td .platform,
.platform-list-messenger-complete:nth-of-type(odd) td .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-messenger-complete td .platform i,
.platform-list-messenger-complete:nth-of-type(odd) td .platform i {
  font-size: 24px;
}
.platform-list-messenger-complete td .label,
.platform-list-messenger-complete:nth-of-type(odd) td .label {
  margin-left: 10px;
}
.platform-list-telegram-complete {
  background-color: #249ddb;
}
.platform-list-telegram-complete:nth-of-type(odd) {
  background-color: #249ddb !important;
}
.platform-list-telegram-complete:hover {
  color: #FFFFFF;
  background-color: #249ddb !important;
}
.platform-list-telegram-complete:hover a {
  color: #FFFFFF;
}
.platform-list-telegram-complete .label-accounts {
  font-size: 20px;
}
.platform-list-telegram-complete td,
.platform-list-telegram-complete:nth-of-type(odd) td {
  font-size: 16px;
  background-color: #249ddb;
  color: #FFFFFF;
}
.platform-list-telegram-complete td a,
.platform-list-telegram-complete:nth-of-type(odd) td a,
.platform-list-telegram-complete td pre,
.platform-list-telegram-complete:nth-of-type(odd) td pre {
  color: #FFFFFF;
}
.platform-list-telegram-complete td a:hover,
.platform-list-telegram-complete:nth-of-type(odd) td a:hover,
.platform-list-telegram-complete td a:active,
.platform-list-telegram-complete:nth-of-type(odd) td a:active,
.platform-list-telegram-complete td a:focus,
.platform-list-telegram-complete:nth-of-type(odd) td a:focus {
  text-decoration: none;
}
.platform-list-telegram-complete td .platform,
.platform-list-telegram-complete:nth-of-type(odd) td .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-telegram-complete td .platform i,
.platform-list-telegram-complete:nth-of-type(odd) td .platform i {
  font-size: 24px;
}
.platform-list-telegram-complete td .label,
.platform-list-telegram-complete:nth-of-type(odd) td .label {
  margin-left: 10px;
}
.platform-list-webchattonic-complete {
  background-color: #ed8a9d;
}
.platform-list-webchattonic-complete:nth-of-type(odd) {
  background-color: #ed8a9d !important;
}
.platform-list-webchattonic-complete:hover {
  color: #FFFFFF;
  background-color: #ed8a9d !important;
}
.platform-list-webchattonic-complete:hover a {
  color: #FFFFFF;
}
.platform-list-webchattonic-complete .label-accounts {
  font-size: 20px;
}
.platform-list-webchattonic-complete td,
.platform-list-webchattonic-complete:nth-of-type(odd) td {
  font-size: 16px;
  background-color: #ed8a9d;
  color: #FFFFFF;
}
.platform-list-webchattonic-complete td a,
.platform-list-webchattonic-complete:nth-of-type(odd) td a,
.platform-list-webchattonic-complete td pre,
.platform-list-webchattonic-complete:nth-of-type(odd) td pre {
  color: #FFFFFF;
}
.platform-list-webchattonic-complete td a:hover,
.platform-list-webchattonic-complete:nth-of-type(odd) td a:hover,
.platform-list-webchattonic-complete td a:active,
.platform-list-webchattonic-complete:nth-of-type(odd) td a:active,
.platform-list-webchattonic-complete td a:focus,
.platform-list-webchattonic-complete:nth-of-type(odd) td a:focus {
  text-decoration: none;
}
.platform-list-webchattonic-complete td .platform,
.platform-list-webchattonic-complete:nth-of-type(odd) td .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-webchattonic-complete td .platform i,
.platform-list-webchattonic-complete:nth-of-type(odd) td .platform i {
  font-size: 24px;
}
.platform-list-webchattonic-complete td .label,
.platform-list-webchattonic-complete:nth-of-type(odd) td .label {
  margin-left: 10px;
}
.platform-list-whatsapp-complete {
  background-color: #25e47b;
}
.platform-list-whatsapp-complete:nth-of-type(odd) {
  background-color: #25e47b !important;
}
.platform-list-whatsapp-complete:hover {
  color: #FFFFFF;
  background-color: #25e47b !important;
}
.platform-list-whatsapp-complete:hover a {
  color: #FFFFFF;
}
.platform-list-whatsapp-complete .label-accounts {
  font-size: 20px;
}
.platform-list-whatsapp-complete td,
.platform-list-whatsapp-complete:nth-of-type(odd) td {
  font-size: 16px;
  background-color: #25e47b;
  color: #FFFFFF;
}
.platform-list-whatsapp-complete td a,
.platform-list-whatsapp-complete:nth-of-type(odd) td a,
.platform-list-whatsapp-complete td pre,
.platform-list-whatsapp-complete:nth-of-type(odd) td pre {
  color: #FFFFFF;
}
.platform-list-whatsapp-complete td a:hover,
.platform-list-whatsapp-complete:nth-of-type(odd) td a:hover,
.platform-list-whatsapp-complete td a:active,
.platform-list-whatsapp-complete:nth-of-type(odd) td a:active,
.platform-list-whatsapp-complete td a:focus,
.platform-list-whatsapp-complete:nth-of-type(odd) td a:focus {
  text-decoration: none;
}
.platform-list-whatsapp-complete td .platform,
.platform-list-whatsapp-complete:nth-of-type(odd) td .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-whatsapp-complete td .platform i,
.platform-list-whatsapp-complete:nth-of-type(odd) td .platform i {
  font-size: 24px;
}
.platform-list-whatsapp-complete td .label,
.platform-list-whatsapp-complete:nth-of-type(odd) td .label {
  margin-left: 10px;
}
.platform-list-unknown-complete {
  background-color: #ffffff;
}
.platform-list-unknown-complete:nth-of-type(odd) {
  background-color: #ffffff !important;
}
.platform-list-unknown-complete:hover {
  color: #000000;
  background-color: #ffffff !important;
}
.platform-list-unknown-complete:hover a {
  color: #000000;
}
.platform-list-unknown-complete .label-accounts {
  font-size: 20px;
}
.platform-list-unknown-complete td,
.platform-list-unknown-complete:nth-of-type(odd) td {
  font-size: 16px;
  background-color: #ffffff;
  color: #000000;
}
.platform-list-unknown-complete td a,
.platform-list-unknown-complete:nth-of-type(odd) td a,
.platform-list-unknown-complete td pre,
.platform-list-unknown-complete:nth-of-type(odd) td pre {
  color: #000000;
}
.platform-list-unknown-complete td a:hover,
.platform-list-unknown-complete:nth-of-type(odd) td a:hover,
.platform-list-unknown-complete td a:active,
.platform-list-unknown-complete:nth-of-type(odd) td a:active,
.platform-list-unknown-complete td a:focus,
.platform-list-unknown-complete:nth-of-type(odd) td a:focus {
  text-decoration: none;
}
.platform-list-unknown-complete td .platform,
.platform-list-unknown-complete:nth-of-type(odd) td .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-unknown-complete td .platform i,
.platform-list-unknown-complete:nth-of-type(odd) td .platform i {
  font-size: 24px;
}
.platform-list-unknown-complete td .label,
.platform-list-unknown-complete:nth-of-type(odd) td .label {
  margin-left: 10px;
}
.platform-list-unknown-disabled-complete {
  background-color: #dddddd;
  font-style: italic;
}
.platform-list-unknown-disabled-complete:nth-of-type(odd) {
  background-color: #dddddd !important;
}
.platform-list-unknown-disabled-complete:hover {
  color: #000000;
  background-color: #dddddd !important;
}
.platform-list-unknown-disabled-complete:hover a {
  color: #000000;
}
.platform-list-unknown-disabled-complete .label-accounts {
  font-size: 20px;
}
.platform-list-unknown-disabled-complete td,
.platform-list-unknown-disabled-complete:nth-of-type(odd) td {
  font-size: 16px;
  background-color: #dddddd;
  color: #000000;
}
.platform-list-unknown-disabled-complete td a,
.platform-list-unknown-disabled-complete:nth-of-type(odd) td a,
.platform-list-unknown-disabled-complete td pre,
.platform-list-unknown-disabled-complete:nth-of-type(odd) td pre {
  color: #000000;
}
.platform-list-unknown-disabled-complete td a:hover,
.platform-list-unknown-disabled-complete:nth-of-type(odd) td a:hover,
.platform-list-unknown-disabled-complete td a:active,
.platform-list-unknown-disabled-complete:nth-of-type(odd) td a:active,
.platform-list-unknown-disabled-complete td a:focus,
.platform-list-unknown-disabled-complete:nth-of-type(odd) td a:focus {
  text-decoration: none;
}
.platform-list-unknown-disabled-complete td .platform,
.platform-list-unknown-disabled-complete:nth-of-type(odd) td .platform {
  display: inline;
  position: relative;
  top: 5px;
  margin-right: 10px;
}
.platform-list-unknown-disabled-complete td .platform i,
.platform-list-unknown-disabled-complete:nth-of-type(odd) td .platform i {
  font-size: 24px;
}
.platform-list-unknown-disabled-complete td .label,
.platform-list-unknown-disabled-complete:nth-of-type(odd) td .label {
  margin-left: 10px;
}
