.bH_oG6p9xx8A0LpBTN2wJ {
  vertical-align: -webkit-baseline-middle;
}
._1RelO-8qquI6Buhfmpr7uj {
  margin-top: 10px;
  margin-bottom: 10px;
}
._3-0NWib9uEeMLJwWINfb21 {
  width: 150px;
  height: 150px;
  border: 1px dashed #ccc;
  border-radius: 4px;
  display: inline-block;
  margin-right: 10px;
}
._34EcRxzNxTY8gh0EusVvTJ {
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
._34EcRxzNxTY8gh0EusVvTJ i {
  color: #ccc;
}
