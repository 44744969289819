._2OzGhpH8CLxLxgYy6sjh4P {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 60px;
  background-color: transparent !important;
  border: none !important;
  color: #FFF !important;
  -webkit-box-shadow: none  !important;
          box-shadow: none  !important;
  height: 51px;
  border-radius: 0px !important;
}
._2OzGhpH8CLxLxgYy6sjh4P:hover {
  background-color: transparent;
  color: #FFF;
}
._2OzGhpH8CLxLxgYy6sjh4P:focus {
  background-color: transparent;
  color: #FFF;
}
