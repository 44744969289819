@font-face {
  font-family: 'icomoon';
  src: url(/manager/static/dist/../fonts/icomoon.eot);
  src: url(/manager/static/dist/../fonts/icomoon.eot#iefix) format('embedded-opentype'), url(/manager/static/dist/../fonts/icomoon.ttf) format('truetype'), url(/manager/static/dist/../fonts/icomoon.woff) format('woff'), url(/manager/static/dist/../fonts/icomoon.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-instagram:before {
  content: "\e90f";
}
.icon-twitter:before {
  content: "\e910";
}
.icon-telegram:before {
  content: "\e905";
}
.icon-messenger:before {
  content: "\e904";
}
.icon-slack:before {
  content: "\e903";
}
.icon-workplace:before {
  content: "\e902";
}
.icon-webchattonic:before {
  content: "\e901";
}
.icon-whatsapp:before {
  content: "\e900";
}
.icon-prev:before {
  content: "\e90c";
}
.icon-next:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e90a";
}
.icon-check:before {
  content: "\e909";
}
.icon-emoji:before {
  content: "\e908";
}
.icon-attach:before {
  content: "\e907";
}
.icon-logout:before {
  content: "\e90d";
}
.icon-send:before {
  content: "\e906";
}
.icon-typing:before {
  content: "\e90e";
}
