.jWi5XX3kNkiR55KCaTZCG {
  display: inline-block;
  margin-bottom: 15px;
  margin-left: 15px;
}
.jWi5XX3kNkiR55KCaTZCG > :first-child {
  margin-right: 5px;
}
._3bO6LtvBfHc5FExR9qpja {
  text-transform: capitalize;
}
