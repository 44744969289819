.container {
  width: 90% !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.panel-mt-color-black {
  border-color: #000000;
}
.panel-mt-color-black > .panel-heading {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.panel-mt-color-black > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #000000;
}
.panel-mt-color-black > .panel-heading .badge {
  color: #000000;
  background-color: #ffffff;
}
.panel-mt-color-black > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #000000;
}
.panel-mt-color-white {
  border-color: #000000;
}
.panel-mt-color-white > .panel-heading {
  color: #000000;
  background-color: #ffffff;
  border-color: #000000;
}
.panel-mt-color-white > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #000000;
}
.panel-mt-color-white > .panel-heading .badge {
  color: #ffffff;
  background-color: #000000;
}
.panel-mt-color-white > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #000000;
}
.panel-mt-color-orange {
  border-color: #e08f24;
}
.panel-mt-color-orange > .panel-heading {
  color: #ffffff;
  background-color: #e08f24;
  border-color: #e08f24;
}
.panel-mt-color-orange > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e08f24;
}
.panel-mt-color-orange > .panel-heading .badge {
  color: #e08f24;
  background-color: #ffffff;
}
.panel-mt-color-orange > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #e08f24;
}
.panel-mt-color-yellow {
  border-color: #beb81e;
}
.panel-mt-color-yellow > .panel-heading {
  color: #ffffff;
  background-color: #beb81e;
  border-color: #beb81e;
}
.panel-mt-color-yellow > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #beb81e;
}
.panel-mt-color-yellow > .panel-heading .badge {
  color: #beb81e;
  background-color: #ffffff;
}
.panel-mt-color-yellow > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #beb81e;
}
.panel-mt-color-dark-gray {
  border-color: #636363;
}
.panel-mt-color-dark-gray > .panel-heading {
  color: #ffffff;
  background-color: #636363;
  border-color: #636363;
}
.panel-mt-color-dark-gray > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #636363;
}
.panel-mt-color-dark-gray > .panel-heading .badge {
  color: #636363;
  background-color: #ffffff;
}
.panel-mt-color-dark-gray > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #636363;
}
.panel-mt-color-light-gray {
  border-color: #c8c8c8;
}
.panel-mt-color-light-gray > .panel-heading {
  color: #000000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}
.panel-mt-color-light-gray > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #c8c8c8;
}
.panel-mt-color-light-gray > .panel-heading .badge {
  color: #c8c8c8;
  background-color: #000000;
}
.panel-mt-color-light-gray > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #c8c8c8;
}
.panel-mt-color-facebook {
  border-color: #3b5998;
}
.panel-mt-color-facebook > .panel-heading {
  color: #ffffff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.panel-mt-color-facebook > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #3b5998;
}
.panel-mt-color-facebook > .panel-heading .badge {
  color: #3b5998;
  background-color: #ffffff;
}
.panel-mt-color-facebook > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #3b5998;
}
.panel-mt-color-teal {
  border-color: #48d1cc;
}
.panel-mt-color-teal > .panel-heading {
  color: #ffffff;
  background-color: #48d1cc;
  border-color: #48d1cc;
}
.panel-mt-color-teal > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #48d1cc;
}
.panel-mt-color-teal > .panel-heading .badge {
  color: #48d1cc;
  background-color: #ffffff;
}
.panel-mt-color-teal > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #48d1cc;
}
.panel-ct-blue {
  border-color: #11384C;
}
.panel-ct-blue > .panel-heading {
  color: #ffffff;
  background-color: #11384C;
  border-color: #11384C;
}
.panel-ct-blue > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #11384C;
}
.panel-ct-blue > .panel-heading .badge {
  color: #11384C;
  background-color: #ffffff;
}
.panel-ct-blue > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #11384C;
}
.panel-ct-lightblue {
  border-color: #31C5DD;
}
.panel-ct-lightblue > .panel-heading {
  color: #ffffff;
  background-color: #31C5DD;
  border-color: #31C5DD;
}
.panel-ct-lightblue > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #31C5DD;
}
.panel-ct-lightblue > .panel-heading .badge {
  color: #31C5DD;
  background-color: #ffffff;
}
.panel-ct-lightblue > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #31C5DD;
}
.panel-ct-gray {
  border-color: #D4D6D6;
}
.panel-ct-gray > .panel-heading {
  color: #ffffff;
  background-color: #D4D6D6;
  border-color: #D4D6D6;
}
.panel-ct-gray > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #D4D6D6;
}
.panel-ct-gray > .panel-heading .badge {
  color: #D4D6D6;
  background-color: #ffffff;
}
.panel-ct-gray > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #D4D6D6;
}
.panel-ct-red {
  border-color: #F95E4E;
}
.panel-ct-red > .panel-heading {
  color: #ffffff;
  background-color: #F95E4E;
  border-color: #F95E4E;
}
.panel-ct-red > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #F95E4E;
}
.panel-ct-red > .panel-heading .badge {
  color: #F95E4E;
  background-color: #ffffff;
}
.panel-ct-red > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #F95E4E;
}
.panel-ct-purple {
  border-color: #422656;
}
.panel-ct-purple > .panel-heading {
  color: #ffffff;
  background-color: #422656;
  border-color: #422656;
}
.panel-ct-purple > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #422656;
}
.panel-ct-purple > .panel-heading .badge {
  color: #422656;
  background-color: #ffffff;
}
.panel-ct-purple > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #422656;
}
.panel-ct-turquoise {
  border-color: #27CCC3;
}
.panel-ct-turquoise > .panel-heading {
  color: #ffffff;
  background-color: #27CCC3;
  border-color: #27CCC3;
}
.panel-ct-turquoise > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #27CCC3;
}
.panel-ct-turquoise > .panel-heading .badge {
  color: #27CCC3;
  background-color: #ffffff;
}
.panel-ct-turquoise > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #27CCC3;
}
.panel-ct-yellow {
  border-color: #F4D345;
}
.panel-ct-yellow > .panel-heading {
  color: #ffffff;
  background-color: #F4D345;
  border-color: #F4D345;
}
.panel-ct-yellow > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #F4D345;
}
.panel-ct-yellow > .panel-heading .badge {
  color: #F4D345;
  background-color: #ffffff;
}
.panel-ct-yellow > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #F4D345;
}
.panel-ct-coral {
  border-color: #ED8A9D;
}
.panel-ct-coral > .panel-heading {
  color: #ffffff;
  background-color: #ED8A9D;
  border-color: #ED8A9D;
}
.panel-ct-coral > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ED8A9D;
}
.panel-ct-coral > .panel-heading .badge {
  color: #ED8A9D;
  background-color: #ffffff;
}
.panel-ct-coral > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ED8A9D;
}
.background-mt-color-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.background-mt-color-black:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.background-mt-color-black:hover a {
  color: #000000;
}
.background-mt-color-white {
  color: #000000;
  background-color: #ffffff;
  border-color: #000000;
}
.background-mt-color-white:hover {
  color: #000000;
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.background-mt-color-white:hover a {
  color: #ffffff;
}
.background-mt-color-orange {
  color: #ffffff;
  background-color: #e08f24;
  border-color: #e08f24;
}
.background-mt-color-orange:hover {
  color: #ffffff;
  background-color: rgba(224, 143, 36, 0.8) !important;
}
.background-mt-color-orange:hover a {
  color: #e08f24;
}
.background-mt-color-yellow {
  color: #ffffff;
  background-color: #beb81e;
  border-color: #beb81e;
}
.background-mt-color-yellow:hover {
  color: #ffffff;
  background-color: rgba(190, 184, 30, 0.8) !important;
}
.background-mt-color-yellow:hover a {
  color: #beb81e;
}
.background-mt-color-dark-gray {
  color: #ffffff;
  background-color: #636363;
  border-color: #636363;
}
.background-mt-color-dark-gray:hover {
  color: #ffffff;
  background-color: rgba(99, 99, 99, 0.8) !important;
}
.background-mt-color-dark-gray:hover a {
  color: #636363;
}
.background-mt-color-light-gray {
  color: #000000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}
.background-mt-color-light-gray:hover {
  color: #000000;
  background-color: rgba(200, 200, 200, 0.8) !important;
}
.background-mt-color-light-gray:hover a {
  color: #c8c8c8;
}
.background-mt-color-facebook {
  color: #ffffff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.background-mt-color-facebook:hover {
  color: #ffffff;
  background-color: rgba(59, 89, 152, 0.8) !important;
}
.background-mt-color-facebook:hover a {
  color: #3b5998;
}
.background-mt-color-teal {
  color: #ffffff;
  background-color: #48d1cc;
  border-color: #48d1cc;
}
.background-mt-color-teal:hover {
  color: #ffffff;
  background-color: rgba(72, 209, 204, 0.8) !important;
}
.background-mt-color-teal:hover a {
  color: #48d1cc;
}
.background-ct-blue {
  color: #ffffff;
  background-color: #11384C;
  border-color: #11384C;
}
.background-ct-blue:hover {
  color: #ffffff;
  background-color: rgba(17, 56, 76, 0.8) !important;
}
.background-ct-blue:hover a {
  color: #11384C;
}
.background-ct-lightblue {
  color: #ffffff;
  background-color: #31C5DD;
  border-color: #31C5DD;
}
.background-ct-lightblue:hover {
  color: #ffffff;
  background-color: rgba(49, 197, 221, 0.8) !important;
}
.background-ct-lightblue:hover a {
  color: #31C5DD;
}
.background-ct-gray {
  color: #ffffff;
  background-color: #D4D6D6;
  border-color: #D4D6D6;
}
.background-ct-gray:hover {
  color: #ffffff;
  background-color: rgba(212, 214, 214, 0.8) !important;
}
.background-ct-gray:hover a {
  color: #D4D6D6;
}
.background-ct-red {
  color: #ffffff;
  background-color: #F95E4E;
  border-color: #F95E4E;
}
.background-ct-red:hover {
  color: #ffffff;
  background-color: rgba(249, 94, 78, 0.8) !important;
}
.background-ct-red:hover a {
  color: #F95E4E;
}
.background-ct-purple {
  color: #ffffff;
  background-color: #422656;
  border-color: #422656;
}
.background-ct-purple:hover {
  color: #ffffff;
  background-color: rgba(66, 38, 86, 0.8) !important;
}
.background-ct-purple:hover a {
  color: #422656;
}
.background-ct-turquoise {
  color: #ffffff;
  background-color: #27CCC3;
  border-color: #27CCC3;
}
.background-ct-turquoise:hover {
  color: #ffffff;
  background-color: rgba(39, 204, 195, 0.8) !important;
}
.background-ct-turquoise:hover a {
  color: #27CCC3;
}
.background-ct-yellow {
  color: #ffffff;
  background-color: #F4D345;
  border-color: #F4D345;
}
.background-ct-yellow:hover {
  color: #ffffff;
  background-color: rgba(244, 211, 69, 0.8) !important;
}
.background-ct-yellow:hover a {
  color: #F4D345;
}
.background-ct-coral {
  color: #ffffff;
  background-color: #ED8A9D;
  border-color: #ED8A9D;
}
.background-ct-coral:hover {
  color: #ffffff;
  background-color: rgba(237, 138, 157, 0.8) !important;
}
.background-ct-coral:hover a {
  color: #ED8A9D;
}
.validation-error {
  color: #a94442;
}
.invalid-field,
.invalid-field.rw-widget,
.invalid-field.rw-widget:hover,
.invalid-field.rw-state-focused {
  border-color: #a94442 !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(169, 68, 66, 0.6) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(169, 68, 66, 0.6) !important;
}
div.form-control[type="number"] {
  padding: 0 !important;
}
@font-face {
  font-family: 'Lato';
  src: url('/manager/static/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('/manager/static/fonts/Lato/Lato-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('/manager/static/fonts/Lato/Lato-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('/manager/static/fonts/Lato/Lato-LightItalic.ttf') format('truetype');
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  src: url('/manager/static/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('/manager/static/fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('/manager/static/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('/manager/static/fonts/Raleway/Raleway-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('/manager/static/fonts/Raleway/Raleway-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('/manager/static/fonts/Raleway/Raleway-LightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Raleway';
  src: url('/manager/static/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url('/manager/static/fonts/Raleway/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
.condition-list-disabled {
  background-color: #DDD !important;
}
.highlight-default {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #D0DEFB;
  color: black;
}
.highlight-warning {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #f0ad4e;
  color: white;
}
.highlight-danger {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #d9534f;
  color: white;
}
.highlight-info {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #5bc0de;
  color: white;
}
.highlight-primary {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #428bca;
  color: white;
}
.highlight-success {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #5cb85c;
  color: white;
}
.highlight-blue {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #11384C;
  color: white;
}
.highlight-lightblue {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #31C5DD;
  color: white;
}
.highlight-gray {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #D4D6D6;
  color: black;
}
.highlight-red {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #F95E4E;
  color: white;
}
.highlight-purple {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #422656;
  color: white;
}
.highlight-turquoise {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #27CCC3;
  color: white;
}
.highlight-yellow {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #F4D345;
  color: black;
}
.highlight-coral {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: #ED8A9D;
  color: white;
}
.highlight-transparent {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 3px;
  text-align: center;
  line-height: 1;
  white-space: nowrap;
  background-color: gray;
  color: white;
}
